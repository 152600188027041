export default class ResponseParser {
  constructor(apiResponse, type) {
    this.type = type;
    this.apiResponse = apiResponse;
  }

  getResponse() {
    let responseMessage = this.getBasicResponse();
    if (!responseMessage)
      switch (this.type) {
        case 'refund':
          responseMessage = this.getRefundResponse();
          break;
        case 'void':
          responseMessage = this.getVoidResponse();
          break;
        default:
          return 'Unexpected error. If the error persist, contact Jupiter support';
      }
    return responseMessage ? responseMessage : 'Unexpected error. If the error persist, contact Jupiter support';
  }

  getBasicResponse() {
    const { response } = this.apiResponse;
    if (response && response.data && response.data.message) {
      if (response.data.message.includes('Invalid Transaction Status'))
        return this.getErrorMessage(
          'Invalid Transaction Status',
          response.data.code
        );
      return this.getErrorMessage(response.data.message, response.data.code);
    } else null;
  }

  getRefundResponse() {
    const { response } = this.apiResponse;
    if (response?.data?.message?.includes(
      'RETURN AMOUNT EXCEEDS ORIGINAL TRANSACTION AMOUNT'
    )) return this.getErrorMessage(
      'Refund amount exceeds original transaction amount',
      response.data.code
    );
    return null;
  }

  getVoidResponse() {
    return null;
  }

  getErrorMessage(message, code) {
    return code ? `Error ${code} - ${message}` : message;
  }
}
