import Vue from 'vue';

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_FIELDS(state, { pageName, fields }) {
      if (!state[pageName]) {
        Vue.set(state, pageName, {});
      }
      Vue.set(state[pageName], 'fields', [...fields]);
    },
    SET_SINGLE_SEARCH(state, { pageName, value }) {
      if (!state[pageName]) {
        Vue.set(state, pageName, {});
      }
      Vue.set(state[pageName], 'singleSearch', value);
    },
    SET_FIELD_VALUE(state, { pageName, fieldName, value }) {
      if (!state[pageName]) {
        Vue.set(state, pageName, { fieldValues: {} });
      }
      if (!state[pageName].fieldValues) {
        Vue.set(state[pageName], 'fieldValues', {});
      }
      Vue.set(state[pageName].fieldValues, fieldName, value);
    },
    SET_ENABLED_FIELDS(state, { pageName, enabledFields }) {
      if (!state[pageName]) {
        Vue.set(state, pageName, { fields: [], fieldValues: {} });
      }

      const enabledByValueFields = state[pageName].fields.filter(field => {
        const fieldValue = state[pageName].fieldValues?.[field.name];
        return fieldValue != null && fieldValue !== '' && fieldValue !== false;
      });

      const uniqueEnabledFields = enabledFields.filter(field =>
        !enabledByValueFields.some(valueField => valueField.name === field.name)
      );

      const allEnabledFields = [...uniqueEnabledFields, ...enabledByValueFields];

      Vue.set(state[pageName], 'enabledFields', allEnabledFields);

      Vue.set(state[pageName], 'fields', state[pageName].fields.map(field => ({
        ...field,
        enabled: allEnabledFields.some(f => f.name === field.name)
      })));
    }
  },
  actions: {
    setFields({ commit }, { pageName, fields }) {
      commit('SET_FIELDS', { pageName, fields });
      commit('SET_ENABLED_FIELDS', { pageName, enabledFields: fields.filter(f => f.enabled) });
    },
    setSingleSearch({ commit, dispatch }, { pageName, value }) {
      commit('SET_SINGLE_SEARCH', { pageName, value });
      if (value) {
        dispatch('clearFilters', { pageName });
      }
    },
    setFieldValue({ commit }, { pageName, fieldName, value }) {
      commit('SET_FIELD_VALUE', { pageName, fieldName, value });
    },
    setEnabledFields({ commit }, { pageName, enabledFields }) {
      commit('SET_ENABLED_FIELDS', { pageName, enabledFields });
    },
    clearFilters({ commit, state }, { pageName }) {
      const fields = state[pageName]?.fields || [];
      const readOnlyFields = fields.filter(f => f.readOnly);

      // Clear all non-readonly field values
      fields.forEach(field => {
        if (!field.readOnly) {
          commit('SET_FIELD_VALUE', {
            pageName,
            fieldName: field.name,
            value: undefined
          });
        }
      });

      // Keep only readonly fields enabled
      commit('SET_ENABLED_FIELDS', {
        pageName,
        enabledFields: readOnlyFields
      });
    }
  },
  getters: {
    filters: state => state
  }
};



