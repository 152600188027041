import { setUiThemeColorsWithHex } from '@commons/uiTheme';
import axios from 'axios';

const FACILITATORS_ENDPOINT = 'foundation/facilitators';

const state = { facilitator: undefined };

const actions = {
  async GET_FACILITATOR({ commit }) {
    try {
      const response = await axios.get(FACILITATORS_ENDPOINT);
      if (response.data && response.data.success) {
        const facilitator = response.data.data;

        if (facilitator.branding) {
          setUiThemeColorsWithHex(facilitator.branding.color);

          if (facilitator.branding.favIcon) {
            // Create new favicon element
            const favicon = document.createElement('link');
            favicon.id = 'facilitator-favicon';
            favicon.rel = 'icon';
            favicon.type = 'image/png';
            favicon.sizes = '96x96';

            // Only remove transparent favicon after new one loads
            favicon.onload = () => {
              document.querySelector('#default-favicon')?.remove();
            };

            // Keep transparent favicon if facilitator favicon fails
            favicon.onerror = () => {
              favicon.remove();
            };

            // Remove any existing facilitator favicon
            document.querySelector('link#facilitator-favicon')?.remove();
            document.head.appendChild(favicon);
            favicon.href = facilitator.branding.favIcon;
          }

          document.documentElement.style.setProperty(
            '--facilitator-background-img-url',
            `url(${facilitator.branding.bgUrl})`
          );
        }
        commit('SET_FACILITATOR', facilitator);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const getters = { getFacilitator: state => state.facilitator };

const mutations = {
  SET_FACILITATOR(state, facilitator) {
    state.facilitator = facilitator;
  }
};

export default {
  state: { ...state },
  actions,
  mutations,
  getters
};
